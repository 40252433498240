import { Events as xt, getOptions as vt, createPicker as bt, FocusTrap as Et, animate as Z, createStyleInjector as Ct } from "picmo";
function V(t) {
  return t.split("-")[0];
}
function N(t) {
  return t.split("-")[1];
}
function K(t) {
  return ["top", "bottom"].includes(V(t)) ? "x" : "y";
}
function at(t) {
  return t === "y" ? "height" : "width";
}
function tt(t, e, n) {
  let {
    reference: o,
    floating: i
  } = t;
  const r = o.x + o.width / 2 - i.width / 2, c = o.y + o.height / 2 - i.height / 2, s = K(e), l = at(s), a = o[l] / 2 - i[l] / 2, d = V(e), f = s === "x";
  let u;
  switch (d) {
    case "top":
      u = {
        x: r,
        y: o.y - i.height
      };
      break;
    case "bottom":
      u = {
        x: r,
        y: o.y + o.height
      };
      break;
    case "right":
      u = {
        x: o.x + o.width,
        y: c
      };
      break;
    case "left":
      u = {
        x: o.x - i.width,
        y: c
      };
      break;
    default:
      u = {
        x: o.x,
        y: o.y
      };
  }
  switch (N(e)) {
    case "start":
      u[s] -= a * (n && f ? -1 : 1);
      break;
    case "end":
      u[s] += a * (n && f ? -1 : 1);
      break;
  }
  return u;
}
const At = async (t, e, n) => {
  const {
    placement: o = "bottom",
    strategy: i = "absolute",
    middleware: r = [],
    platform: c
  } = n, s = await (c.isRTL == null ? void 0 : c.isRTL(e));
  let l = await c.getElementRects({
    reference: t,
    floating: e,
    strategy: i
  }), {
    x: a,
    y: d
  } = tt(l, o, s), f = o, u = {}, p = 0;
  for (let m = 0; m < r.length; m++) {
    const {
      name: h,
      fn: w
    } = r[m], {
      x: y,
      y: g,
      data: v,
      reset: x
    } = await w({
      x: a,
      y: d,
      initialPlacement: o,
      placement: f,
      strategy: i,
      middlewareData: u,
      rects: l,
      platform: c,
      elements: {
        reference: t,
        floating: e
      }
    });
    if (a = y != null ? y : a, d = g != null ? g : d, u = {
      ...u,
      [h]: {
        ...u[h],
        ...v
      }
    }, x && p <= 50) {
      p++, typeof x == "object" && (x.placement && (f = x.placement), x.rects && (l = x.rects === !0 ? await c.getElementRects({
        reference: t,
        floating: e,
        strategy: i
      }) : x.rects), {
        x: a,
        y: d
      } = tt(l, f, s)), m = -1;
      continue;
    }
  }
  return {
    x: a,
    y: d,
    placement: f,
    strategy: i,
    middlewareData: u
  };
};
function Pt(t) {
  return {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    ...t
  };
}
function Ot(t) {
  return typeof t != "number" ? Pt(t) : {
    top: t,
    right: t,
    bottom: t,
    left: t
  };
}
function I(t) {
  return {
    ...t,
    top: t.y,
    left: t.x,
    right: t.x + t.width,
    bottom: t.y + t.height
  };
}
async function Q(t, e) {
  var n;
  e === void 0 && (e = {});
  const {
    x: o,
    y: i,
    platform: r,
    rects: c,
    elements: s,
    strategy: l
  } = t, {
    boundary: a = "clippingAncestors",
    rootBoundary: d = "viewport",
    elementContext: f = "floating",
    altBoundary: u = !1,
    padding: p = 0
  } = e, m = Ot(p), w = s[u ? f === "floating" ? "reference" : "floating" : f], y = I(await r.getClippingRect({
    element: (n = await (r.isElement == null ? void 0 : r.isElement(w))) == null || n ? w : w.contextElement || await (r.getDocumentElement == null ? void 0 : r.getDocumentElement(s.floating)),
    boundary: a,
    rootBoundary: d,
    strategy: l
  })), g = I(r.convertOffsetParentRelativeRectToViewportRelativeRect ? await r.convertOffsetParentRelativeRectToViewportRelativeRect({
    rect: f === "floating" ? {
      ...c.floating,
      x: o,
      y: i
    } : c.reference,
    offsetParent: await (r.getOffsetParent == null ? void 0 : r.getOffsetParent(s.floating)),
    strategy: l
  }) : c[f]);
  return {
    top: y.top - g.top + m.top,
    bottom: g.bottom - y.bottom + m.bottom,
    left: y.left - g.left + m.left,
    right: g.right - y.right + m.right
  };
}
const Lt = Math.min, Rt = Math.max;
function et(t, e, n) {
  return Rt(t, Lt(e, n));
}
const kt = {
  left: "right",
  right: "left",
  bottom: "top",
  top: "bottom"
};
function z(t) {
  return t.replace(/left|right|bottom|top/g, (e) => kt[e]);
}
function ft(t, e, n) {
  n === void 0 && (n = !1);
  const o = N(t), i = K(t), r = at(i);
  let c = i === "x" ? o === (n ? "end" : "start") ? "right" : "left" : o === "start" ? "bottom" : "top";
  return e.reference[r] > e.floating[r] && (c = z(c)), {
    main: c,
    cross: z(c)
  };
}
const Tt = {
  start: "end",
  end: "start"
};
function G(t) {
  return t.replace(/start|end/g, (e) => Tt[e]);
}
const Bt = ["top", "right", "bottom", "left"], St = /* @__PURE__ */ Bt.reduce((t, e) => t.concat(e, e + "-start", e + "-end"), []);
function Dt(t, e, n) {
  return (t ? [...n.filter((i) => N(i) === t), ...n.filter((i) => N(i) !== t)] : n.filter((i) => V(i) === i)).filter((i) => t ? N(i) === t || (e ? G(i) !== i : !1) : !0);
}
const Vt = function(t) {
  return t === void 0 && (t = {}), {
    name: "autoPlacement",
    options: t,
    async fn(e) {
      var n, o, i, r, c;
      const {
        x: s,
        y: l,
        rects: a,
        middlewareData: d,
        placement: f,
        platform: u,
        elements: p
      } = e, {
        alignment: m = null,
        allowedPlacements: h = St,
        autoAlignment: w = !0,
        ...y
      } = t, g = Dt(m, w, h), v = await Q(e, y), x = (n = (o = d.autoPlacement) == null ? void 0 : o.index) != null ? n : 0, b = g[x];
      if (b == null)
        return {};
      const {
        main: H,
        cross: j
      } = ft(b, a, await (u.isRTL == null ? void 0 : u.isRTL(p.floating)));
      if (f !== b)
        return {
          x: s,
          y: l,
          reset: {
            placement: g[0]
          }
        };
      const _ = [v[V(b)], v[H], v[j]], E = [...(i = (r = d.autoPlacement) == null ? void 0 : r.overflows) != null ? i : [], {
        placement: b,
        overflows: _
      }], B = g[x + 1];
      if (B)
        return {
          data: {
            index: x + 1,
            overflows: E
          },
          reset: {
            placement: B
          }
        };
      const S = E.slice().sort((P, W) => P.overflows[0] - W.overflows[0]), $ = (c = S.find((P) => {
        let {
          overflows: W
        } = P;
        return W.every((yt) => yt <= 0);
      })) == null ? void 0 : c.placement, D = $ != null ? $ : S[0].placement;
      return D !== f ? {
        data: {
          index: x + 1,
          overflows: E
        },
        reset: {
          placement: D
        }
      } : {};
    }
  };
};
function Nt(t) {
  const e = z(t);
  return [G(t), e, G(e)];
}
const Ft = function(t) {
  return t === void 0 && (t = {}), {
    name: "flip",
    options: t,
    async fn(e) {
      var n;
      const {
        placement: o,
        middlewareData: i,
        rects: r,
        initialPlacement: c,
        platform: s,
        elements: l
      } = e, {
        mainAxis: a = !0,
        crossAxis: d = !0,
        fallbackPlacements: f,
        fallbackStrategy: u = "bestFit",
        flipAlignment: p = !0,
        ...m
      } = t, h = V(o), y = f || (h === c || !p ? [z(c)] : Nt(c)), g = [c, ...y], v = await Q(e, m), x = [];
      let b = ((n = i.flip) == null ? void 0 : n.overflows) || [];
      if (a && x.push(v[h]), d) {
        const {
          main: E,
          cross: B
        } = ft(o, r, await (s.isRTL == null ? void 0 : s.isRTL(l.floating)));
        x.push(v[E], v[B]);
      }
      if (b = [...b, {
        placement: o,
        overflows: x
      }], !x.every((E) => E <= 0)) {
        var H, j;
        const E = ((H = (j = i.flip) == null ? void 0 : j.index) != null ? H : 0) + 1, B = g[E];
        if (B)
          return {
            data: {
              index: E,
              overflows: b
            },
            reset: {
              placement: B
            }
          };
        let S = "bottom";
        switch (u) {
          case "bestFit": {
            var _;
            const $ = (_ = b.map((D) => [D, D.overflows.filter((P) => P > 0).reduce((P, W) => P + W, 0)]).sort((D, P) => D[1] - P[1])[0]) == null ? void 0 : _[0].placement;
            $ && (S = $);
            break;
          }
          case "initialPlacement":
            S = c;
            break;
        }
        if (o !== S)
          return {
            reset: {
              placement: S
            }
          };
      }
      return {};
    }
  };
};
async function $t(t, e) {
  const {
    placement: n,
    platform: o,
    elements: i
  } = t, r = await (o.isRTL == null ? void 0 : o.isRTL(i.floating)), c = V(n), s = N(n), l = K(n) === "x", a = ["left", "top"].includes(c) ? -1 : 1, d = r && l ? -1 : 1, f = typeof e == "function" ? e(t) : e;
  let {
    mainAxis: u,
    crossAxis: p,
    alignmentAxis: m
  } = typeof f == "number" ? {
    mainAxis: f,
    crossAxis: 0,
    alignmentAxis: null
  } : {
    mainAxis: 0,
    crossAxis: 0,
    alignmentAxis: null,
    ...f
  };
  return s && typeof m == "number" && (p = s === "end" ? m * -1 : m), l ? {
    x: p * d,
    y: u * a
  } : {
    x: u * a,
    y: p * d
  };
}
const nt = function(t) {
  return t === void 0 && (t = 0), {
    name: "offset",
    options: t,
    async fn(e) {
      const {
        x: n,
        y: o
      } = e, i = await $t(e, t);
      return {
        x: n + i.x,
        y: o + i.y,
        data: i
      };
    }
  };
};
function Wt(t) {
  return t === "x" ? "y" : "x";
}
const it = function(t) {
  return t === void 0 && (t = {}), {
    name: "shift",
    options: t,
    async fn(e) {
      const {
        x: n,
        y: o,
        placement: i
      } = e, {
        mainAxis: r = !0,
        crossAxis: c = !1,
        limiter: s = {
          fn: (w) => {
            let {
              x: y,
              y: g
            } = w;
            return {
              x: y,
              y: g
            };
          }
        },
        ...l
      } = t, a = {
        x: n,
        y: o
      }, d = await Q(e, l), f = K(V(i)), u = Wt(f);
      let p = a[f], m = a[u];
      if (r) {
        const w = f === "y" ? "top" : "left", y = f === "y" ? "bottom" : "right", g = p + d[w], v = p - d[y];
        p = et(g, p, v);
      }
      if (c) {
        const w = u === "y" ? "top" : "left", y = u === "y" ? "bottom" : "right", g = m + d[w], v = m - d[y];
        m = et(g, m, v);
      }
      const h = s.fn({
        ...e,
        [f]: p,
        [u]: m
      });
      return {
        ...h,
        data: {
          x: h.x - n,
          y: h.y - o
        }
      };
    }
  };
};
function ut(t) {
  return t && t.document && t.location && t.alert && t.setInterval;
}
function R(t) {
  if (t == null)
    return window;
  if (!ut(t)) {
    const e = t.ownerDocument;
    return e && e.defaultView || window;
  }
  return t;
}
function C(t) {
  return R(t).getComputedStyle(t);
}
function O(t) {
  return ut(t) ? "" : t ? (t.nodeName || "").toLowerCase() : "";
}
function dt() {
  const t = navigator.userAgentData;
  return t != null && t.brands ? t.brands.map((e) => e.brand + "/" + e.version).join(" ") : navigator.userAgent;
}
function A(t) {
  return t instanceof R(t).HTMLElement;
}
function k(t) {
  return t instanceof R(t).Element;
}
function Mt(t) {
  return t instanceof R(t).Node;
}
function F(t) {
  if (typeof ShadowRoot > "u")
    return !1;
  const e = R(t).ShadowRoot;
  return t instanceof e || t instanceof ShadowRoot;
}
function U(t) {
  const {
    overflow: e,
    overflowX: n,
    overflowY: o
  } = C(t);
  return /auto|scroll|overlay|hidden/.test(e + o + n);
}
function Ht(t) {
  return ["table", "td", "th"].includes(O(t));
}
function ht(t) {
  const e = /firefox/i.test(dt()), n = C(t);
  return n.transform !== "none" || n.perspective !== "none" || n.contain === "paint" || ["transform", "perspective"].includes(n.willChange) || e && n.willChange === "filter" || e && (n.filter ? n.filter !== "none" : !1);
}
function pt() {
  return !/^((?!chrome|android).)*safari/i.test(dt());
}
const ot = Math.min, M = Math.max, X = Math.round;
function L(t, e, n) {
  var o, i, r, c;
  e === void 0 && (e = !1), n === void 0 && (n = !1);
  const s = t.getBoundingClientRect();
  let l = 1, a = 1;
  e && A(t) && (l = t.offsetWidth > 0 && X(s.width) / t.offsetWidth || 1, a = t.offsetHeight > 0 && X(s.height) / t.offsetHeight || 1);
  const d = k(t) ? R(t) : window, f = !pt() && n, u = (s.left + (f && (o = (i = d.visualViewport) == null ? void 0 : i.offsetLeft) != null ? o : 0)) / l, p = (s.top + (f && (r = (c = d.visualViewport) == null ? void 0 : c.offsetTop) != null ? r : 0)) / a, m = s.width / l, h = s.height / a;
  return {
    width: m,
    height: h,
    top: p,
    right: u + m,
    bottom: p + h,
    left: u,
    x: u,
    y: p
  };
}
function T(t) {
  return ((Mt(t) ? t.ownerDocument : t.document) || window.document).documentElement;
}
function q(t) {
  return k(t) ? {
    scrollLeft: t.scrollLeft,
    scrollTop: t.scrollTop
  } : {
    scrollLeft: t.pageXOffset,
    scrollTop: t.pageYOffset
  };
}
function mt(t) {
  return L(T(t)).left + q(t).scrollLeft;
}
function jt(t) {
  const e = L(t);
  return X(e.width) !== t.offsetWidth || X(e.height) !== t.offsetHeight;
}
function _t(t, e, n) {
  const o = A(e), i = T(e), r = L(
    t,
    o && jt(e),
    n === "fixed"
  );
  let c = {
    scrollLeft: 0,
    scrollTop: 0
  };
  const s = {
    x: 0,
    y: 0
  };
  if (o || !o && n !== "fixed")
    if ((O(e) !== "body" || U(i)) && (c = q(e)), A(e)) {
      const l = L(e, !0);
      s.x = l.x + e.clientLeft, s.y = l.y + e.clientTop;
    } else
      i && (s.x = mt(i));
  return {
    x: r.left + c.scrollLeft - s.x,
    y: r.top + c.scrollTop - s.y,
    width: r.width,
    height: r.height
  };
}
function gt(t) {
  return O(t) === "html" ? t : t.assignedSlot || t.parentNode || (F(t) ? t.host : null) || T(t);
}
function st(t) {
  return !A(t) || C(t).position === "fixed" ? null : It(t);
}
function It(t) {
  let {
    offsetParent: e
  } = t, n = t, o = !1;
  for (; n && n !== e; ) {
    const {
      assignedSlot: i
    } = n;
    if (i) {
      let r = i.offsetParent;
      if (C(i).display === "contents") {
        const c = i.hasAttribute("style"), s = i.style.display;
        i.style.display = C(n).display, r = i.offsetParent, i.style.display = s, c || i.removeAttribute("style");
      }
      n = i, e !== r && (e = r, o = !0);
    } else if (F(n) && n.host && o)
      break;
    n = F(n) && n.host || n.parentNode;
  }
  return e;
}
function zt(t) {
  let e = gt(t);
  for (F(e) && (e = e.host); A(e) && !["html", "body"].includes(O(e)); ) {
    if (ht(e))
      return e;
    {
      const n = e.parentNode;
      e = F(n) ? n.host : n;
    }
  }
  return null;
}
function J(t) {
  const e = R(t);
  let n = st(t);
  for (; n && Ht(n) && C(n).position === "static"; )
    n = st(n);
  return n && (O(n) === "html" || O(n) === "body" && C(n).position === "static" && !ht(n)) ? e : n || zt(t) || e;
}
function rt(t) {
  if (A(t))
    return {
      width: t.offsetWidth,
      height: t.offsetHeight
    };
  const e = L(t);
  return {
    width: e.width,
    height: e.height
  };
}
function Xt(t) {
  let {
    rect: e,
    offsetParent: n,
    strategy: o
  } = t;
  const i = A(n), r = T(n);
  if (n === r)
    return e;
  let c = {
    scrollLeft: 0,
    scrollTop: 0
  };
  const s = {
    x: 0,
    y: 0
  };
  if ((i || !i && o !== "fixed") && ((O(n) !== "body" || U(r)) && (c = q(n)), A(n))) {
    const l = L(n, !0);
    s.x = l.x + n.clientLeft, s.y = l.y + n.clientTop;
  }
  return {
    ...e,
    x: e.x - c.scrollLeft + s.x,
    y: e.y - c.scrollTop + s.y
  };
}
function Yt(t, e) {
  const n = R(t), o = T(t), i = n.visualViewport;
  let r = o.clientWidth, c = o.clientHeight, s = 0, l = 0;
  if (i) {
    r = i.width, c = i.height;
    const a = pt();
    (a || !a && e === "fixed") && (s = i.offsetLeft, l = i.offsetTop);
  }
  return {
    width: r,
    height: c,
    x: s,
    y: l
  };
}
function Kt(t) {
  var e;
  const n = T(t), o = q(t), i = (e = t.ownerDocument) == null ? void 0 : e.body, r = M(n.scrollWidth, n.clientWidth, i ? i.scrollWidth : 0, i ? i.clientWidth : 0), c = M(n.scrollHeight, n.clientHeight, i ? i.scrollHeight : 0, i ? i.clientHeight : 0);
  let s = -o.scrollLeft + mt(t);
  const l = -o.scrollTop;
  return C(i || n).direction === "rtl" && (s += M(n.clientWidth, i ? i.clientWidth : 0) - r), {
    width: r,
    height: c,
    x: s,
    y: l
  };
}
function wt(t) {
  const e = gt(t);
  return ["html", "body", "#document"].includes(O(e)) ? t.ownerDocument.body : A(e) && U(e) ? e : wt(e);
}
function Y(t, e) {
  var n;
  e === void 0 && (e = []);
  const o = wt(t), i = o === ((n = t.ownerDocument) == null ? void 0 : n.body), r = R(o), c = i ? [r].concat(r.visualViewport || [], U(o) ? o : []) : o, s = e.concat(c);
  return i ? s : s.concat(Y(c));
}
function Ut(t, e) {
  const n = e.getRootNode == null ? void 0 : e.getRootNode();
  if (t.contains(e))
    return !0;
  if (n && F(n)) {
    let o = e;
    do {
      if (o && t === o)
        return !0;
      o = o.parentNode || o.host;
    } while (o);
  }
  return !1;
}
function qt(t, e) {
  const n = L(t, !1, e === "fixed"), o = n.top + t.clientTop, i = n.left + t.clientLeft;
  return {
    top: o,
    left: i,
    x: i,
    y: o,
    right: i + t.clientWidth,
    bottom: o + t.clientHeight,
    width: t.clientWidth,
    height: t.clientHeight
  };
}
function ct(t, e, n) {
  return e === "viewport" ? I(Yt(t, n)) : k(e) ? qt(e, n) : I(Kt(T(t)));
}
function Gt(t) {
  const e = Y(t), o = ["absolute", "fixed"].includes(C(t).position) && A(t) ? J(t) : t;
  return k(o) ? e.filter((i) => k(i) && Ut(i, o) && O(i) !== "body") : [];
}
function Jt(t) {
  let {
    element: e,
    boundary: n,
    rootBoundary: o,
    strategy: i
  } = t;
  const c = [...n === "clippingAncestors" ? Gt(e) : [].concat(n), o], s = c[0], l = c.reduce((a, d) => {
    const f = ct(e, d, i);
    return a.top = M(f.top, a.top), a.right = ot(f.right, a.right), a.bottom = ot(f.bottom, a.bottom), a.left = M(f.left, a.left), a;
  }, ct(e, s, i));
  return {
    width: l.right - l.left,
    height: l.bottom - l.top,
    x: l.left,
    y: l.top
  };
}
const Qt = {
  getClippingRect: Jt,
  convertOffsetParentRelativeRectToViewportRelativeRect: Xt,
  isElement: k,
  getDimensions: rt,
  getOffsetParent: J,
  getDocumentElement: T,
  getElementRects: (t) => {
    let {
      reference: e,
      floating: n,
      strategy: o
    } = t;
    return {
      reference: _t(e, J(n), o),
      floating: {
        ...rt(n),
        x: 0,
        y: 0
      }
    };
  },
  getClientRects: (t) => Array.from(t.getClientRects()),
  isRTL: (t) => C(t).direction === "rtl"
};
function Zt(t, e, n, o) {
  o === void 0 && (o = {});
  const {
    ancestorScroll: i = !0,
    ancestorResize: r = !0,
    elementResize: c = !0,
    animationFrame: s = !1
  } = o, l = i && !s, a = r && !s, d = l || a ? [...k(t) ? Y(t) : [], ...Y(e)] : [];
  d.forEach((h) => {
    l && h.addEventListener("scroll", n, {
      passive: !0
    }), a && h.addEventListener("resize", n);
  });
  let f = null;
  if (c) {
    let h = !0;
    f = new ResizeObserver(() => {
      h || n(), h = !1;
    }), k(t) && !s && f.observe(t), f.observe(e);
  }
  let u, p = s ? L(t) : null;
  s && m();
  function m() {
    const h = L(t);
    p && (h.x !== p.x || h.y !== p.y || h.width !== p.width || h.height !== p.height) && n(), p = h, u = requestAnimationFrame(m);
  }
  return n(), () => {
    var h;
    d.forEach((w) => {
      l && w.removeEventListener("scroll", n), a && w.removeEventListener("resize", n);
    }), (h = f) == null || h.disconnect(), f = null, s && cancelAnimationFrame(u);
  };
}
const te = (t, e, n) => At(t, e, {
  platform: Qt,
  ...n
});
async function ee(t, e, n) {
  if (!n)
    throw new Error("Must provide a positioning option");
  return await (typeof n == "string" ? ne(t, e, n) : ie(t, n));
}
async function ne(t, e, n) {
  if (!e)
    throw new Error("Reference element is required for relative positioning");
  let o;
  return n === "auto" ? o = {
    middleware: [
      Vt(),
      it(),
      nt({ mainAxis: 5, crossAxis: 12 })
    ]
  } : o = {
    placement: n,
    middleware: [
      Ft(),
      it(),
      nt(5)
    ]
  }, Zt(e, t, async () => {
    const { x: i, y: r } = await te(e, t, o);
    Object.assign(t.style, {
      position: "absolute",
      left: `${i}px`,
      top: `${r}px`
    });
  });
}
function ie(t, e) {
  return t.style.position = "fixed", Object.entries(e).forEach(([n, o]) => {
    t.style[n] = o;
  }), () => {
  };
}
const oe = {
  hideOnClickOutside: !0,
  hideOnEmojiSelect: !0,
  hideOnEscape: !0,
  position: "auto",
  showCloseButton: !0
};
function se(t = {}) {
  return {
    ...oe,
    rootElement: document.body,
    ...t
  };
}
const re = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/></svg>', lt = {
  popupContainer: "popupContainer",
  closeButton: "closeButton"
};
class ce {
  constructor(e, n) {
    this.isOpen = !1, this.externalEvents = new xt(), this.options = { ...se(n), ...vt(e) }, this.popupEl = document.createElement("div"), this.popupEl.classList.add(lt.popupContainer), this.popupEl.classList.add(this.options.theme), n.className && this.popupEl.classList.add(n.className), this.options.showCloseButton && (this.closeButton = document.createElement("button"), this.closeButton.type = "button", this.closeButton.classList.add(lt.closeButton), this.closeButton.innerHTML = re, this.popupEl.appendChild(this.closeButton));
    const o = document.createElement("div");
    this.popupEl.appendChild(o), this.picker = bt({ ...this.options, rootElement: o }), this.focusTrap = new Et(), this.picker.addEventListener("data:ready", () => {
      this.focusTrap.activate(this.picker.el), this.picker.setInitialFocus();
    }), this.options.hideOnEmojiSelect && this.picker.addEventListener("emoji:select", () => {
      var i;
      this.close(), (i = this.triggerElement) == null || i.focus();
    }), this.options.hideOnClickOutside && (this.onDocumentClick = this.onDocumentClick.bind(this), document.addEventListener("click", this.onDocumentClick)), this.options.hideOnEscape && (this.handleKeydown = this.handleKeydown.bind(this), this.popupEl.addEventListener("keydown", this.handleKeydown)), this.referenceElement = this.options.referenceElement, this.triggerElement = this.options.triggerElement;
  }
  addEventListener(e, n) {
    this.externalEvents.on(e, n), this.picker.addEventListener(e, n);
  }
  removeEventListener(e, n) {
    this.externalEvents.off(e, n), this.picker.removeEventListener(e, n);
  }
  handleKeydown(e) {
    var n;
    e.key === "Escape" && (this.close(), (n = this.triggerElement) == null || n.focus());
  }
  async destroy() {
    this.isOpen && await this.close(), document.removeEventListener("click", this.onDocumentClick), this.picker.destroy(), this.externalEvents.removeAll();
  }
  toggle(e) {
    return this.isOpen ? this.close() : this.open(e);
  }
  async open({ triggerElement: e, referenceElement: n } = {}) {
    this.isOpen || (e && (this.triggerElement = e), n && (this.referenceElement = n), await this.initiateOpenStateChange(!0), this.options.rootElement.appendChild(this.popupEl), await this.setPosition(), this.picker.reset(), await this.animatePopup(!0), await this.animateCloseButton(!0), this.picker.setInitialFocus(), this.externalEvents.emit("picker:open"));
  }
  async close() {
    var e;
    !this.isOpen || (await this.initiateOpenStateChange(!1), await this.animateCloseButton(!1), await this.animatePopup(!1), this.popupEl.remove(), this.picker.reset(), (e = this.positionCleanup) == null || e.call(this), this.focusTrap.deactivate(), this.externalEvents.emit("picker:close"));
  }
  getRunningAnimations() {
    return this.picker.el.getAnimations().filter((e) => e.playState === "running");
  }
  async setPosition() {
    var e;
    (e = this.positionCleanup) == null || e.call(this), this.positionCleanup = await ee(
      this.popupEl,
      this.referenceElement,
      this.options.position
    );
  }
  awaitPendingAnimations() {
    return Promise.all(this.getRunningAnimations().map((e) => e.finished));
  }
  onDocumentClick(e) {
    var i;
    const n = e.target, o = (i = this.triggerElement) == null ? void 0 : i.contains(n);
    this.isOpen && !this.picker.isPickerClick(e) && !o && this.close();
  }
  animatePopup(e) {
    return Z(
      this.picker.el,
      {
        opacity: [0, 1],
        transform: ["scale(0.9)", "scale(1)"]
      },
      {
        duration: 150,
        id: e ? "show-picker" : "hide-picker",
        easing: "ease-in-out",
        direction: e ? "normal" : "reverse",
        fill: "both"
      },
      this.options
    );
  }
  animateCloseButton(e) {
    if (this.closeButton)
      return Z(
        this.closeButton,
        {
          opacity: [0, 1]
        },
        {
          duration: 25,
          id: e ? "show-close" : "hide-close",
          easing: "ease-in-out",
          direction: e ? "normal" : "reverse",
          fill: "both"
        },
        this.options
      );
  }
  async initiateOpenStateChange(e) {
    this.isOpen = e, await this.awaitPendingAnimations();
  }
}
const le = `.popupContainer{display:flex;flex-direction:column;position:absolute}.popupContainer .closeButton{position:absolute;opacity:0;background:transparent;border:none;z-index:1;right:0;top:0;cursor:pointer;padding:4px;align-self:flex-end;transform:translate(50%,-50%);background:#999999;width:1.5rem;height:1.5rem;display:flex;align-items:center;justify-content:center;border-radius:50%}.popupContainer .closeButton:hover{background:var(--accent-color)}.popupContainer .closeButton svg{fill:#fff;width:1.25rem;height:1.25rem}
`, ae = Ct();
function ue(t, e) {
  return ae(le), new ce({
    autoFocus: "auto",
    ...t
  }, e);
}
export {
  ce as PopupPickerController,
  ue as createPopup
};
